<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <v-card id="account-setting-card">
        <!-- tabs -->
        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <!-- tabs item -->
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card
              flat
              class="pa-3 mt-3"
            >
              <v-form class="multi-col-validation">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="accountSettingData.nickname"
                        :label="t('Nickname')"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="accountSettingData.name"
                        :label="t('Name')"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="accountSettingData.email"
                        :label="t('E-mail')"
                        dense
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="accountSettingData.password"
                        :label="t('Enter New Password')"
                        dense
                        outlined
                      ></v-text-field>

                      <v-alert
                        color="warning"
                        text
                        class="mb-0 mt-3"
                      >
                        <div class="d-flex align-start">
                          <v-icon color="warning">
                            {{ icons.mdiAlertOutline }}
                          </v-icon>

                          <div class="ms-3">
                            <p class="text-base font-weight-medium mb-1">
                              {{ t('Only change your password when necessary') }}
                            </p>
                          </div>
                        </div>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="primary"
                    :loading="loading"
                    :disabled="loading"
                    class="mt-3"
                    @click="updateUser()"
                  >
                    {{ t('Save') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiAccountOutline, mdiAlertOutline } from '@mdi/js'
import { ref, inject, computed } from '@vue/composition-api'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')

    // Properties
    const loading = ref(false)
    const tab = ref('')
    const tabs = computed(() => (
      [
        { title: t('Account'), icon: mdiAccountOutline },
      ]
    ))
    const accountSettingData = JSON.parse(localStorage.getItem('userData'))

    // Methods
    const updateUser = () => {
      loading.value = true
      store
        .dispatch('updateUser', { user: accountSettingData })
        .then(response => {
          loading.value = false
          const { data } = response.data
          delete data.permissions
          localStorage.setItem('userData', JSON.stringify(data))
          snackbarService.success('Account updated successfully!')
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while updating your account. Please try again later!')
        })
    }

    return {
      t,
      loading,
      tab,
      tabs,
      accountSettingData,
      updateUser,
      icons: {
        mdiAccountOutline,
        mdiAlertOutline,
      },
    }
  },
}
</script>
